<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <input type="text" v-bind:id="component_id" v-bind:name="component_meta.variable" v-bind:value="component_value">
</template>

<script>
import $ from 'jquery';
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    // 屬性初始化
    let localMeta = this.field;

    // 預設值 及 初始化
    let localValue = this.value;

    // 最小值
    if (typeof localMeta.component.properties.min !== 'number') {
      localMeta.component.properties.min = 1;
    }

    // 最大值
    if (typeof localMeta.component.properties.max !== 'number') {
      localMeta.component.properties.min = 10;
    }

    // 每次移動的值
    if (typeof localMeta.component.properties.step !== 'number') {
      localMeta.component.properties.step = 1;
    }


    return {
      component_id: 'slider-' + Math.random().toString().substring(2),
      component_value: localValue,
      component_meta: localMeta
    }
  },
  mounted() {
    let $this = this;

    this.component_value = parseInt(this.component_value);
    if (typeof this.component_value !== 'number' || isNaN(this.component_value)) {
      this.component_value = this.component_meta.component.properties.default;
      this.update_input_value();
    }

    $('#' + this.component_id).ionRangeSlider({
      min: $this.component_meta.component.properties.min,
      max: $this.component_meta.component.properties.max,
      type: 'single',
      step: $this.component_meta.component.properties.step,
      postfix: ' ' + $this.component_meta.component.properties.postfix,
      grid_snap: true,
      prettify: false,
      hasGrid: true,
      onChange: function (obj) {
        $this.component_value = obj.from;
        $this.update_input_value();
      }
    });

    // ionRangeSlider 預設值為 this.component_value
    $('#' + this.component_id).data("ionRangeSlider").update({
      from: this.component_value
    });
  },
  methods: {
    update_input_value: function () {
      let $event = { 'target': { 'value': 0 } };
      $event.target.value = this.component_value;
      this.$emit('input', $event);
    }
  }
}
</script>

<style>
span.tip {
  color: green;
}

/* 設定滑塊的背景和選定範圍的顏色 */
.irs {
  background-color: #f5f5f5;
}

.irs .irs-bar {
  background-color: #007bff;
}

/* 設定滑塊的標籤和按鈕的顏色 */
.irs .irs-handle {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.irs .irs-max {
  color: #007bff;
}

.irs .irs-from,
.irs .irs-to {
  color: #4CAF50;
}
</style>